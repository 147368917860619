var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShow)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],class:[
    'fast-input-item-wrapper',
    _vm.className,
    _vm.hasLabel ? 'hasLabel' : '',
    _vm.isButton ? 'isButton' : '',
    _vm.isRadio ? 'isRadio' : '',
    _vm.readonly ? 'readonly' : '',
    _vm.inputSearchHasWidth ? 'inputSearchHasWidth' : '',
    _vm.isDescription ? 'isDescription' : '',
    _vm.isUnderline ? 'isUnderline' : '',
  ],style:(_vm.inputStyle),on:{"keydown":_vm.onKeydown}},[_c('a-form-item',{class:['form-item-flex'],attrs:{"label-col":{ flex: `0 0 ${_vm.labelWidth || '130px'}` },"wrapper-col":_vm.isDescription ? { flex: 1 } : { flex: `0 0 ${_vm.contentWidth}` },"colon":_vm.colon}},[(_vm.label && _vm.config?.isTodo)?_c('span',{staticClass:"form-item-label-box",attrs:{"slot":"label","id":_vm.config.dataIndex},slot:"label"},[_vm._t(_vm.config.customLabelRender,function(){return [_vm._v(" "+_vm._s(_vm.config.serialNo || '')+_vm._s(_vm.label)+" ")]},{"label":_vm.label})],2):(_vm.label)?_c('span',{staticClass:"form-item-label-box",attrs:{"slot":"label"},slot:"label"},[_vm._t(_vm.config.customLabelRender,function(){return [_vm._v(" "+_vm._s(_vm.config.serialNo || '')+_vm._s(_vm.label)+" ")]},{"label":_vm.label})],2):_vm._e(),_c('div',{staticClass:"form-item-controls-wrapper",class:[_vm.config.className ?? '']},[_c('fast-input',_vm._b({directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorator),expression:"decorator"}],attrs:{"config":_vm.config,"isRow":_vm.isRow,"formConfig":_vm.formConfig},on:{"query":_vm.emitQuery,"change":_vm.onChange},scopedSlots:_vm._u([{key:_vm.scopedSlots.customRender,fn:function(slotProps){return [_vm._t(_vm.scopedSlots.customRender,null,null,slotProps)]}}],null,true)},'fast-input',_vm.config.elementProps || {},false)),(_vm.tooltip)?_c('span',{staticClass:"tooltip-container"},[_c('a-tooltip',{attrs:{"title":_vm.tooltip}},[_c('a-icon',{attrs:{"type":"info-circle"}})],1)],1):_vm._e()],1),(_vm.desc)?_c('div',{staticClass:"form-item-controls-desc"},[_c('span',[_vm._v(_vm._s(_vm.desc))])]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }